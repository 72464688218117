import React from "react"
import "../styles/fontawesome-free-5.15.4-web/css/all.css"

export default function footer() { 
    return(
    <>
    {/*<!--Footer-->*/}
    <footer class="bg-white shadow">
      <div class="container mx-auto">
        <div class="w-full flex flex-row justify-between">
          <div class="flex-auto text-black">
            <a class="font-serif no-underline hover:no-underline font-bold text-2xl sm:text-4xl" href="/">
            <i class="fas fa-cogs">Nordrefit</i>
            </a>
          </div>
          
          <div class="flex-auto">
          </div>

          <div class="flex-auto">
          </div>

          <div class="flex-auto text-right">
            <a href="/datenschutz" class="no-underline hover:underline text-gray-500 sm:text-base md:text-2xl">Datenschutz</a>
          </div>

          <div class="flex-auto text-right">
            <a href="/impressum" class="no-underline hover:underline text-gray-500 sm:text-base md:text-2xl">Impressum</a>
          </div>
        </div>
        <div class="w-full flex bg-white text-black text-sm">
          &copy; Nordrefit GmbH&nbsp; <a href="https://www.stritago.de" rel="nofollow">| stritago.de </a>
        </div>
      </div>
    </footer>
    </>
    )
}