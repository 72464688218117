import * as React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"

const Layout = ({ children }) => (
        <>
<head>
<script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="5f66f29f-c575-4d0b-909f-314095fef3cf" data-blockingmode="auto" type="text/javascript"></script>
</head>      
        <body class="leading-normal tracking-normal text-white gradient">
                <Navbar></Navbar>  
                        {children}
                <Footer></Footer> 
        </body>   
        </>
);

export default Layout
