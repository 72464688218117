import React from "react"
import Helmet from "react-helmet"
import "../styles/fontawesome-free-5.15.4-web/css/all.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function navbar(){ 
return(
  <>
  <Helmet>
    <script src={'../script.js'} type="text/javascript" />
  </Helmet>
  {/*<!--Nav-->*/}    
    <nav id="header" class="fixed bg-white w-full z-30 top-0 shadow">
      <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div class="pl-4 flex items-center">        
          <a class="font-serif toggleColour text-black no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="/">
          <i class="fas fa-cogs"></i>Nordrefit
          </a>
        </div>
        <div class="block lg:hidden pr-4">
          <button id="nav-toggle" class="flex items-center p-1 text-gray-800 hover:text-gray-800 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            <i class="fas fa-bars"></i>
          </button>
        </div>
        <div id="nav-content" class="block w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent p-4 lg:p-0 z-20">
                <ul class="list-reset lg:flex justify-end flex-1 items-center">
                      <li class="mr-3">
                        <AnchorLink to="/#Unternehmen">
                          <a class="inline-block hover:underline py-2 px-4 text-gray-800 font-bold no-underline hover:text-gray-800 hover:text-underline">Unternehmen</a>
                        </AnchorLink>
                      </li>
                      <li class="mr-3">
                        <AnchorLink to="/#Dienstleistungen">
                          <a class="inline-block hover:underline py-2 px-4 text-gray-800 font-bold no-underline hover:text-gray-800 hover:text-underline">Dienstleistungen</a>
                        </AnchorLink>
                      </li>
                </ul>

          <AnchorLink to="/#Kontakt">
            <button id="navAction"
              class="mx-auto lg:mx-0 hover:underline border-2 text-gray-500 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none 
              focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
              Kontakt
            </button>
          </AnchorLink>
        </div>    
      </div>
      <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
    </nav>
  </>
)
}